import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Cookies from 'js-cookie';
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { nanoid } from "nanoid";
import qs from 'query-string';
import { MediaContextProvider } from 'day8-react/build/new-components/MediaContext/MediaContext';
import '../styles/styles.scss';
import { LuckyModalProvider } from '../context/LuckyModalContext';
import LuckyModal from '../components/lucky-week/LuckyModal';
import { Toaster } from 'day8-ui';
const isDev = process.env.NODE_ENV === 'development';

const CurrencyProvider = dynamic(() => import('day8-ui').then(mod => mod.CurrencyProvider), {
  ssr: false
});

let gb_custom_visitor_id: string | null = null;

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  trackingCallback: (experiment, result) => {
    window?.dataLayer?.push({
      event: 'experiment_viewed',
      experiment_id: experiment.key,
      variation_id: result.key
    });
  }
});

growthbook.init();

interface CustomAppProps extends AppProps {
  pageProps: {
    dehydratedState: {}
  }
}
function MyApp({ Component, pageProps, router }: CustomAppProps) {
  useEffect(() => {
    // Initialize gb_custom_visitor_id on client side
    gb_custom_visitor_id = localStorage.getItem("gb_custom_visitor_id");
    if (!gb_custom_visitor_id) {
      gb_custom_visitor_id = nanoid();
      localStorage.setItem("gb_custom_visitor_id", gb_custom_visitor_id);
    }

    // Handle affiliate code
    const { tywRef }: { tywRef?: string } = qs.parse(window.location.search);
    if (tywRef) {
      Cookies.set('tyw_affiliate_code', tywRef, { expires: 90 });
    }
  }, []);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    growthbook.setURL(window.location.href);
    growthbook.setAttributes({
      id: gb_custom_visitor_id,
      user_id: user?.id,
      query: window.location.search,
      host: window.location.host,
      loggedIn: user ? true : false,
      deviceType: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
      employee: user?.email?.endsWith("@day8.com") ? true : false,
      country: user?.country,
      browser: navigator.userAgent,
      url: router.pathname,
      utmSource: Cookies.get('utm_source'),
      utmMedium: Cookies.get('utm_medium'),
      utmCampaign: Cookies.get('utm_campaign'),
      utmContent: Cookies.get('utm_content'),
      utmTerm: Cookies.get('utm_term'),
    });
  }, [router.pathname]);
  const AnyComponent = Component as any;
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <CurrencyProvider>
        <MediaContextProvider disableDynamicMediaQueries={isDev}>
          <LuckyModalProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Head>
            <AnyComponent {...pageProps} />
            <LuckyModal />
            <Toaster position="top-right" style={{ marginTop: '64px' }} />
          </LuckyModalProvider>
        </MediaContextProvider>
      </CurrencyProvider>
    </GrowthBookProvider>
  );
}

export default MyApp;